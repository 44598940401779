import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import BigLogo from "../components/biglogo"
import Apple from "../components/apple"
import Spotify from "../components/spotify"
import Google from "../components/google"
import LinkedIn from "../components/linkedin"
import Twitter from "../components/twitter"
import Thomas from "../components/thomas"
import Federico from "../components/federico"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Bitcoin Cabana Podcast" />

      <div style={{ maxWidth: `600px`, marginBottom: `1.45rem`, margin: `0 auto`}}>
        <BigLogo />

        <div style={{marginBottom: `2.9rem`, marginTop: `2.9rem`}}>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem` }}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://podcasts.apple.com/it/podcast/bitcoin-cabana/id1561631462" target="_blank" rel="noreferrer"><Apple/></a>
            </div>
          </div>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem`}}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://open.spotify.com/show/2GqAcWtIJ7Yf2FXspe6zk6?si=fef18c104e124280" target="_blank" rel="noreferrer"><Spotify/></a>
            </div>
          </div>
          <div style={{ float: `left`, width: `33%`, padding: `0 0.5rem`}}>
            <div style={{maxWidth: `200px`, margin: `0 auto`}}>
              <a href="https://music.youtube.com/playlist?list=PLyZCe9pfvEaRsjhCMx_OoTgmZJXSItMdN" target="_blank" rel="noreferrer"><Google/></a>
            </div>
          </div>
          <div style={{ clear: `both`}}>
          </div>
        </div>
        <div style={{marginBottom: `2.9rem`}}>
          <h3 style={{textAlign: `center`}}>
          Bitcoin Cabana Podcast esplora Bitcoin, macroeconomia e le tesi di investimento che ne emergono. 
          </h3>
          <p>
          E' nato come salotto serale tra amici dove confrontarsi sulle news dal mondo e le mode del momento.. ogni riferimento a NFT, DeFi e blockchain è puramente casuale. 
          "Cabana" perché ci sono tante storie interessanti che legano Bitcoin a vari paradisi tropicali, a volte anche fiscali, se ti incuriosisce inizia ad ascolarci e partecipa ai live del lunedì sera, 21:00, sul canale <a href="https://t.me/bitcoincabana">Telegram</a>!
          </p>
        </div>
        
        <div style={{marginBottom: `2.9rem`}}>
          <h3 style={{textAlign: `center`}}>
          Chi siamo 
          </h3>
        </div>
        <div style={{marginBottom: `2.9rem`}}>
          <div style={{ float: `left`, width: `50%`, padding: `0 1rem 0 0` }}>
            <div>
              <div style={{maxWidth: `150px`, margin: `0 auto 1.45rem`}}>
                <Thomas/>
              </div>
              Thomas Rossi, <br/>
              Startup Founder, connoisseur di meme maximalisti.
            </div>
            <div style={{align:'center'}}>
            <a href="https://www.linkedin.com/in/thomasr0ssi/" target="_blank" rel="noreferrer"><LinkedIn/></a>
            <a href="https://twitter.com/th_s4m0ht" target="_blank" rel="noreferrer"><Twitter/></a>
            </div>


          </div>
          <div style={{ float: `left`, width: `50%`, padding: `0 0 0 1rem `}}>
            <div>
              <div style={{maxWidth: `150px`, margin: `0 auto 1.45rem`}}>
                <Federico/>
              </div>
              Federico Francolini, <br/>
              Managing Director, newyorker tornato nel bel paese. 
            </div>
            <div style={{align:'center'}}>
            <a href="https://www.linkedin.com/in/federico-francolini-7b04372a/" target="_blank" rel="noreferrer"><LinkedIn/></a>
            <a href="https://twitter.com/FedericoFrancol" target="_blank" rel="noreferrer"><Twitter/></a>
            </div>
          </div>
          <div style={{ clear: `both`}}>
          </div>
        </div>

        

      </div>

  </Layout>
)

export default IndexPage
